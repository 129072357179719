import React from 'react';
// import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../components/layout';
import SEO from '../components/seo'
import { theme } from '../components/Elements/theme'

import { ContentWrapper } from '../components/Elements'

import { Grid, Container, Segment } from 'semantic-ui-react'

import CTASection from '../components/CTA Section';

const Description = styled.div`
  h2 {
    font-size: 3rem;
  }
  h3 {
    color: grey;
    font-weight: 400;
    margin-top: 0.5rem;
  }
  p {
    font-size: 1.2rem;
  }
  @media (max-width: ${theme.breakpoints.ms}),
    (max-device-width: ${theme.breakpoints.ms}) {
      h2 {
        font-size: 2.4rem;
      }
  }
  @media (max-width: ${theme.breakpoints.xxs}),
    (max-device-width: ${theme.breakpoints.xxs}) {
      h2 {
        font-size: 1.8rem;
      }
  }
`

const kadraTemplate = ({data}) => {
  const { fixed } = data.datoCmsHeadersSection.heroImage
  const { name, positionId, imageCasual, opisNode: { childMarkdownRemark } } = data.datoCmsPerson
  return (
    <Layout>
      <SEO title={ name } />  
       <Segment basic style={{ 
          minHeight: '50vh'
         }} vertical textAlign='center'>
        <Img 
          fixed={ fixed }
          imgStyle={{ objectPosition: 'center center' }}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        />
      </Segment>
      <ContentWrapper>
        <Grid centered stackable>
          <Grid.Row>
            <Grid.Column width={8}> 
              <Container textAlign='center'>
                <Img fluid={ imageCasual.fluid } />
              </Container>
            </Grid.Column>
            <Grid.Column width={8} >
              <Container textAlign='justified'>
                <Description dangerouslySetInnerHTML={{ __html: childMarkdownRemark.html }}></Description>
              </Container>
            </Grid.Column>
          </Grid.Row> 
        </Grid>
      </ContentWrapper>
      <CTASection />
    </Layout>
  );
};

export default kadraTemplate;

export const pageQuery = graphql`
  query KadraBySlug($uid: String!) {

    datoCmsHeadersSection(name: { eq: "kadra" }) {
      id
      name
      header
      heroImage {
        fixed(width: 900, height: 460) {
        ...GatsbyDatoCmsFixed
        }
        fluid(maxWidth: 1200, maxHeight: 460) {
        ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsPerson( uid: { eq: $uid} ) {
      uid
      name
      positionId
      opisNode {
        childMarkdownRemark {
          html
        }
      }
      imageFormal {
        fluid(imgixParams: {fit: "crop", w: "290", h: "400", q: 75, auto: "enhance"}) {
        ...GatsbyDatoCmsFluid
        }
      }
      imageCasual {
        fluid(imgixParams: {fit: "crop", w: "290", h: "400", q: 75, auto: "enhance"}) {
        ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;


